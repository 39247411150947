//react libraries import
import React from 'react';


//custom libraries import
import DialogTextBox from '../components/dialogTextBox';
import notices from '../components/notices.json';

const NoticeEventsPage = () => {

    return(
        <main>
            <div className="w-full pt-12 pb-8 px-6 bg-light-primary">
            <div className="text-center font-bold text-primary text-2xl">
                Events & Notices
            </div>
            <div className="w-full mt-4 px-2 grid grid-flow-col grid-cols-1 md:grid-cols-2 grid-rows-5 md:grid-rows-3 gap-4 justify-around">
                {
                notices.map( ( notice, index ) => {
                    return (
                    <DialogTextBox key={index} data={notice} isHome={false}/>
                    );
                })
                }
            </div>
            </div>
        </main>
    );

}

export default NoticeEventsPage;